import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Carousel.css";
import image1 from "../Assets/images/carouselimage1.webp";
import image2 from "../Assets/images/carouselimage3.webp";
import image3 from "../Assets/images/carouselimage2.webp";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Carousel() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="Carousel">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        }}
        loop={true}
        speed={1000}
        navigation={false}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={image1} alt="Carousel slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} alt="Carousel slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} alt="Carousel slide 3" />
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination"></div>

      <div className="carousel-text">
        <h1>ARASTAGH.AM</h1>
        <p>{t("carousel.description")}</p>
        <button onClick={() => navigate("/about")}>
          {t("carousel.button")}
        </button>
      </div>
    </div>
  );
}

export default Carousel;
