import React from "react";
import "./Ceiling.css";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CeilingPage() {
  const { t } = useTranslation();
  const { ceilingName } = useParams();
  const navigate = useNavigate();

  const ceilings = t("ceilings", { returnObjects: true });

  const ceilingData = ceilings.find(
    (ceiling) => ceiling.name_en.toLowerCase() === ceilingName.toLowerCase()
  );

  if (!ceilingData) {
    return (
      <div className="Ceiling">
        <h1>{t("ceilingNotFound")}</h1>
        <button onClick={() => navigate("/")}>{t("gobackbutton")}</button>
      </div>
    );
  }

  return (
    <div className="Ceiling">
      <div className="ceiling-header">
        <h1>{ceilingData.name}</h1>
      </div>

      <div className="ceiling-content">
        <div className="ceiling-content-image">
          <img
            src={require(`../Assets/images/${ceilingData.name_en.toLowerCase()}.webp`)}
            alt={ceilingData.name}
          />
        </div>

        <div className="ceiling-content-details">
          <p className="ceiling-short-desc">{ceilingData.description}</p>
          {ceilingData.longDescription && <p>{ceilingData.longDescription}</p>}
          <button className="back-button" onClick={() => navigate(-1)}>
            {t("gobackbutton")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CeilingPage;
