import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.css";
import arastaghLogo from "../Assets/images/arastaghamLogo.webp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const langs = ["ՀԱՅ", "ENG"];

  const initialLang =
    localStorage.getItem("selectedLanguage") === "arm" ? "ՀԱՅ" : "ENG";
  const [selectedLang, setSelectedLang] = useState(initialLang);

  const options = {
    ՀԱՅ: [
      { name: "Գլխավոր", path: "/" },
      { name: "Մեր Մասին", path: "/about" },
      { name: "Տեսականի", path: "/catalog" },
      { name: "Կապ", path: "/contact" },
    ],
    ENG: [
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about" },
      { name: "Catalog", path: "/catalog" },
      { name: "Contact", path: "/contact" },
    ],
  };

  const dropdownRef = useRef(null);
  const menuDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (
        menuDropdownRef.current &&
        !menuDropdownRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    }

    if (isDropdownOpen || isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isMenuOpen]);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  const handleLanguageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const handleOptionClick = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <div className="Header">
      <div className="header">
        <div className="logo-container">
          <img
            src={arastaghLogo}
            alt="Logo"
            className="logo"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="options-container">
          {options[selectedLang].map((option, index) => (
            <p
              key={index}
              className="option"
              onClick={() => navigate(option.path)}
            >
              {option.name}
            </p>
          ))}
        </div>

        <div className="language-container" ref={dropdownRef}>
          <div className="language" onClick={handleLanguageClick}>
            <p>{selectedLang}</p>
            <KeyboardArrowDownIcon />
          </div>

          <div className={`language-dropdown ${isDropdownOpen ? "open" : ""}`}>
            <ul>
              {langs.map((lang, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSelectedLang(lang);
                    setIsDropdownOpen(false);
                    if (lang === "ENG") {
                      changeLanguage("en");
                    } else if (lang === "ՀԱՅ") {
                      changeLanguage("arm");
                    }
                  }}
                >
                  {lang}
                </li>
              ))}
            </ul>
          </div>

          <div className="dropdown-options" ref={menuDropdownRef}>
            <MenuIcon className="menu-icon" onClick={handleMenuClick} />
            <div className={`menu-dropdown ${isMenuOpen ? "open" : ""}`}>
              <ul>
                {options[selectedLang].map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleOptionClick(option.path)}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
